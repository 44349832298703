import React, { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, makeStyles, TextField } from '@material-ui/core';
import tripicon from '../../../../assets/img/espicons/tripicon.png';
import TRipImage from '../../../../assets/img/delivery guy.png';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  height: '760px',

  // borderRadius: '7px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  button: {
    margin: theme.spacing(1),
  },
  viewButton: {
    backgroundColor: '#3366FF',
    color: 'white',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '30px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
}));
const Personaldetails = ({
  active,
  tripDetails,
  remarks,
  approvedAmount,
  approvedAmountRecieved,
  rejectionESP,
  status,
  tripData,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [remark, setRemarks] = useState('');
  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState('');
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return curr;
    }
    return 0;
  };
  const handleRemarks = value => {
    setRemarks(value);
    remarks(value);
  };
  const handleActive = value => {
    active(value);
  };
  const handleApprovedAmount = value => {
    const regex = /^[1-9]\d*(\.\d+)?$/;
    const valid = regex.test(value);
    if (valid) {
      if (value <= 10000 && value <= tripDetails?.requested_balance) {
        setError(false);
        setAmount(value);
        approvedAmount(value);
      } else {
        setError(true);
        setMessage(
          'Amount should not exceed 10000 & less than requested balance'
        );
      }
    } else {
      setError(true);
      setMessage('Please check amount');
    }
  };
  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '400px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <img src={tripicon} alt="tripicon" />
              </div>
              <Typography variant="h6" gutterBottom>
                Trip Details
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={8}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">From City :</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {tripDetails?.from_city}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">To City :</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {tripDetails?.to_city}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">Truck Number :</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {tripDetails?.truck_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">Truck Type :</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {tripDetails?.truck_type}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">Freight Value :</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {toIndianCurrency(tripDetails?.freight_value)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">Advance :</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {toIndianCurrency(tripDetails?.advance)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">
                      Requested Balance :
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">
                      {toIndianCurrency(tripDetails?.requested_balance)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle2"
                      style={{ marginTop: '6px' }}
                    >
                      Approved Amount :
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {status === 'approved' ? (
                      <Typography variant="body1">
                        {toIndianCurrency(tripData.approved_balance)}
                      </Typography>
                    ) : (
                      <TextField
                        onChange={event =>
                          handleApprovedAmount(event.target.value)
                        }
                        variant="outlined"
                        size="small"
                        placeholder="₹0.00"
                        error={error}
                        helperText={error ? message : ''}
                      />
                    )}
                  </Grid>
                  <Grid container spacing={2} className={classes.profileAction}>
                    <Grid item xs={12}>
                      <textarea
                        style={{
                          padding: '10px',
                          width: '100%',
                          // marginBottom: '10px',
                          borderRadius: '5px',
                          border: '1px solid lightgray',
                        }}
                        placeholder="Remarks"
                        rows={2}
                        onChange={event => handleRemarks(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        onClick={rejectionESP}
                        disabled={
                          remark === '' ||
                          status === 'approved' ||
                          status === 'rejected'
                        }
                      >
                        Reject
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={profileImageContainer}>
                <img src={TRipImage} alt="imagecions" />
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            margin: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleActive(0)}
            className={classes.button}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          {status === 'approved' || status !== 'rejected' ? (
            <Button
              onClick={() => handleActive(2)}
              color="primary"
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              // disabled={amount === ''}
              className={classes.viewButton}
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={() => handleActive(2)}
              color="primary"
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              disabled={amount === ''}
              className={classes.viewButton}
            >
              Next
            </Button>
          )}
        </div>
      </DialogContent>
    </div>
  );
};

export default Personaldetails;
