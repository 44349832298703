/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import apiCaller from '../../../utils/apiCallerESP';

const options = ['Success', 'Reversed'];
const useStyles = makeStyles(theme => ({
  viewButton: {
    backgroundColor: '#3366FF',
    color: 'white',
    margin: '10px',
  },
  alert: { padding: '10px' },
}));
export default function AlertDialog({ status, data, triggerRefresh }) {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openRejectedReason, setOpenRejectedReason] = React.useState(false);
  const [UTR, setUTR] = React.useState('');
  const [error, setError] = React.useState(false);
  const [remarks, setRemarks] = React.useState('');
  const [value, setValue] = React.useState('');
  const { transaction_id, trip_id, utr } = data;
  const classes = useStyles();
  const handleChange = event => {
    setValue(event.target.value);
  };

  const selectStyles =
    status == 'processing'
      ? { backgroundColor: '#3366FF', color: 'white', width: '100px' }
      : status == 'processed'
      ? { backgroundColor: '#00811C', color: 'white', width: '100px' }
      : status == 'reversed'
      ? { backgroundColor: '#FF0000', color: 'white', width: '100px' }
      : status == 'success'
      ? { backgroundColor: '#00811C', color: 'white', width: '100px' }
      : '';
  const selectStyles2 =
    status == 'processing'
      ? { backgroundColor: '#3366FF', color: 'white' }
      : status == 'processed'
      ? { backgroundColor: '#00811C', color: 'white' }
      : status == 'reversed'
      ? { backgroundColor: '#FF0000', color: 'white' }
      : status == 'success'
      ? { backgroundColor: '#00811C', color: 'white' }
      : '';
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    // console.log();
  };

  const handleRejected = async () => {
    const endPoint = 'balancerequest-esp';
    const body = {
      transaction_id,
      status: 'reversed',
      trip_id,
      utr,
      remark: value,
    };
    try {
      await apiCaller(endPoint, body);
      triggerRefresh();
    } catch (e) {
      console.log(e);
    }
  };
  const openRejectDialog = () => {
    setOpenRejectedReason(true);
  };

  const handleSelect = selectedValue => {
    if (selectedValue === 'Success') {
      setOpen(true);
    }
    if (selectedValue == 'Reversed') {
      openRejectDialog();
    }
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseReverserModal = () => {
    setOpenRejectedReason(false);
  };
  const handleUTR = val => {
    const regex = /^[0-9a-zA-Z]+$/;
    if (regex.test(val)) {
      setUTR(val);

      setError(false);
    } else {
      // setUTR('');
      setError(true);
    }
  };
  const handleEnableESP = async () => {
    const endPoint = 'balancerequest-esp';
    const body = {
      transaction_id,
      utr: UTR,
      remark: remarks,
      status: 'processed',
      // trip_id,
    };
    try {
      await apiCaller(endPoint, body);
      triggerRefresh();
    } catch (e) {
      console.log(e);
    }
    // console.log(response);
  };

  const handleCloseMenu = event => {
    setAnchorEl(null);
  };
  return (
    <div>
      <div>
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
          size="small"
        >
          <Button style={selectStyles} size="small">
            {status === 'processed' ? 'Success' : status}
          </Button>
          {status === 'processed' || status === 'reversed' ? (
            ''
          ) : (
            <Button
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleClick}
              style={selectStyles2}
              disabled={status === 'processed' || status === 'reversed'}
            >
              <ArrowRightIcon />
            </Button>
          )}
        </ButtonGroup>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {options.map(option => (
            <MenuItem onClick={() => handleSelect(option)}>{option}</MenuItem>
          ))}
        </Menu>
      </div>

      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.alert}>
          <DialogTitle id="alert-dialog-title">Please Provide UTR</DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={4}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="UTR"
                  error={error}
                  helperText={error ? 'Please enter correct UTR' : ''}
                  onChange={event => handleUTR(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Remarks"
                  onChange={event => setRemarks(event.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={handleEnableESP}
              variant="contained"
              color="primary"
              className={classes.viewButton}
              disabled={UTR === '' || error}
              autoFocus
            >
              Submit
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={openRejectedReason}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.alert}>
          <DialogTitle id="alert-dialog-title">
            Please select reason
          </DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Reject"
                name="reject reason"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Insufficient funds in their account"
                  control={<Radio color="primary" />}
                  label="Insufficient funds in their account"
                />
                <FormControlLabel
                  value="Bad Payment gateway"
                  control={<Radio color="primary" />}
                  label="Bad Payment gateway"
                />
                <FormControlLabel
                  value="Incorrect bank details"
                  control={<Radio color="primary" />}
                  label="Incorrect bank details"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseReverserModal} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={handleRejected}
              variant="contained"
              color="primary"
              className={classes.viewButton}
            >
              Submit
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
