import React, { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, makeStyles } from '@material-ui/core';
import ewayicon from '../../../../assets/img/espicons/ewayicon.png';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  height: '760px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImage = { width: '300px', height: '400px', objectFit: 'contain' };
const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActions: { display: 'flex', alignContent: 'center' },
}));
const PODdetails = ({ active, tripDetails, remarks, rejectionESP, status }) => {
  const classes = useStyles();
  const [remark, setRemarks] = useState('');
  //   const handleClose = () => {
  //     setOpen(false);
  //   };
  const handleRemarks = value => {
    setRemarks(value);
    remarks(value);
  };
  const handleActive = value => {
    active(value);
  };

  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '400px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <img src={ewayicon} alt="ewayicon" />
              </div>
              <Typography variant="h6" gutterBottom>
                E-Way Bill
              </Typography>
            </div>
          </legend>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={profileImageContainer}>
                <img
                  src={tripDetails?.eway_bill}
                  alt="icon"
                  style={profileImage}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '7%',
                }}
              >
                <textarea
                  style={{
                    padding: '10px',
                    width: '100%',
                    borderRadius: '5px',
                    border: '1px solid lightgray',
                  }}
                  placeholder="Remarks"
                  rows={2}
                  onChange={event => handleRemarks(event.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={rejectionESP}
                  disabled={
                    remark === '' ||
                    status === 'approved' ||
                    status === 'rejected'
                  }
                >
                  Reject
                </Button>
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            margin: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleActive(2)}
            className={classes.button}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            // onClick={handleClose}
            onClick={() => handleActive(4)}
            color="primary"
            variant="contained"
            className={classes.viewButton}
            endIcon={<ArrowForwardIcon />}
          >
            Next
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default PODdetails;
