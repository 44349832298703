/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import swal from '@sweetalert/with-react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import { Button, makeStyles } from '@material-ui/core';
import BankIllustration from '../../../../assets/img/Bank Illustration.png';
import apiCaller from '../../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  height: '760px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  button: {
    margin: theme.spacing(1),
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '10px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  activeButton: {
    backgroundColor: '#FF0000',
    color: 'white',
    marginTop: '10px',
  },
  Button: { margin: '10px' },
  viewButton: {
    backgroundColor: '#3366FF',
    color: 'white',
  },
}));
const Adhaardetails = ({
  active,
  agentDetails,
  agentId,
  user,
  rejectAgentHandle,
  status,
}) => {
  const classes = useStyles();
  const [flag, setFlag] = React.useState(false);
  const {
    agent_name,
    account_name,
    account_number,
    bank_branch_name,
    bank_name,
    ifsc_code,
  } = agentDetails;
  const [remark, setRemark] = React.useState('');
  const handleReject = async () => {
    const endpoint = 'broker-approve';
    const data = {
      agent_id: agentId.toString(),
      transporter_id: user.username,
      approval_status: false,
      remark,
    };
    await apiCaller(endpoint, data);
    setFlag(!flag);
    rejectAgentHandle(false);
  };
  const handleActive = value => {
    active(value);
  };
  const handleApprove = async () => {
    const endpoint = 'broker-approve';
    const data = {
      agent_id: agentId.toString(),
      transporter_id: user.username,
      approval_status: true,
      remark,
    };
    try {
      await apiCaller(endpoint, data);
      swal({
        title: 'Broker Approval!',
        text: `You approved broker request for ${agent_name}!`,
        icon: 'success',
        timer: 2000,
        button: false,
      }).then(() => {
        setFlag(!flag);
        rejectAgentHandle(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '400px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <AccountBalanceIcon style={{ color: 'white' }} />
              </div>
              <Typography variant="h6" gutterBottom>
                Account Details
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <div style={profileImageContainer}>
                <img
                  src={BankIllustration}
                  style={{ objectFit: 'fill', width: '400px' }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">Account Name :</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">{account_name}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">
                      Account Number :
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">{account_number}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">Bank Name :</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">{bank_name}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">ISFC Code :</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">{ifsc_code}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">Branch :</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">{bank_branch_name}</Typography>
                  </Grid>
                  {/* <Grid container spacing={2} className={classes.profileAction}> */}
                  <Grid item xs={12}>
                    <textarea
                      style={{
                        padding: '10px',
                        width: '100%',
                        // marginBottom: '10px',
                        borderRadius: '5px',
                        border: '1px solid lightgray',
                      }}
                      placeholder="Remarks"
                      rows={2}
                      onChange={event => setRemark(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={
                        flag ? classes.activeButton : classes.viewButton
                      }
                      onClick={handleReject}
                      disabled={remark === '' || status === 'approved'}
                    >
                      Reject
                    </Button>
                  </Grid>
                  {/* </Grid> */}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            margin: '20px',
          }}
        >
          {' '}
          <Button
            variant="contained"
            onClick={() => handleActive(3)}
            className={classes.button}
            startIcon={<ArrowBackIcon />}
            // size="small"
          >
            Back
          </Button>
          <Button
            onClick={handleApprove}
            // onClick={}
            // size="small"
            color="primary"
            variant="contained"
            endIcon={<CheckIcon />}
            disabled={status === 'approved' || status === 'rejected'}
          >
            Approve
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default Adhaardetails;
