/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Button, makeStyles } from '@material-ui/core';
import adhaaricon from '../../../../assets/img/espicons/adhaaricon.png';
import apiCaller from '../../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  height: '760px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImage = { width: '700px', height: '400px', objectFit: 'cover' };
const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  button: {
    margin: theme.spacing(1),
  },
  DialogContent: { minHeight: '500px' },
  profileAction: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActions: { display: 'flex', alignContent: 'center' },
  activeButton: {
    backgroundColor: '#FF0000',
    color: 'white',
    // marginTop: '10px',
  },
  viewButton: {
    backgroundColor: '#3366FF',
    color: 'white',
  },
}));
const Personaldetails = ({
  active,
  agentDetails,
  agentId,
  user,
  rejectAgentHandle,
  status,
}) => {
  const classes = useStyles();
  const { cancelled_cheque } = agentDetails;
  const [flag, setFlag] = React.useState(false);
  const [remark, setRemark] = React.useState('');
  const handleReject = async () => {
    const endpoint = 'broker-approve';
    const data = {
      agent_id: agentId.toString(),
      transporter_id: user.username,
      approval_status: false,
      remark,
    };
    await apiCaller(endpoint, data);
    setFlag(!flag);
    rejectAgentHandle(false);
  };
  const handleActive = value => {
    active(value);
  };

  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '400px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <img src={adhaaricon} alt="adhaarimage" />
              </div>
              <Typography variant="h6" gutterBottom>
                Canceled Cheque
              </Typography>
            </div>
          </legend>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <div style={profileImageContainer}>
                <img src={cancelled_cheque} style={profileImage} />
              </div>
            </Grid>

            <Grid item xs={12}>
              <textarea
                style={{
                  padding: '10px',
                  width: '100%',
                  // marginBottom: '10px',
                  borderRadius: '5px',
                  border: '1px solid lightgray',
                }}
                placeholder="Remarks"
                rows={2}
                onChange={event => setRemark(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                className={flag ? classes.activeButton : ''}
                variant="contained"
                onClick={handleReject}
                disabled={
                  remark === '' ||
                  status === 'approved' ||
                  status === 'rejected'
                }
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            margin: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleActive(2)}
            className={classes.button}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            // onClick={handleClose}
            className={classes.viewButton}
            onClick={() => handleActive(4)}
            color="primary"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            Next
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default Personaldetails;
