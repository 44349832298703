/* eslint-disable no-self-assign */
/* eslint-disable no-var */
/* eslint-disable camelcase */
import { Grid, Button, Typography } from '@material-ui/core';
import moment from 'moment';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import CachedIcon from '@material-ui/icons/Cached';
import RequestsListView from './ESPRequests/RequestsListView';
import apiCaller from '../../utils/apiCallerESP';

var offset = 0;
const ESPRequest = () => {
  // const classes = useStyles();
  const [user, setUser] = React.useState();
  const [tripDetails, setTripDetails] = React.useState();
  const [next, setNext] = React.useState(false);
  const getTripList = async (userID, Offset) => {
    const endPoint = 'esp-request-list';
    const data = {
      transporter_id: userID,
      limit: '10',
      offset: Offset || 0,
    };
    try {
      const response = await apiCaller(endPoint, data);
      const { trip_list } = response.data.body;
      if (trip_list.length < 10) {
        setNext(true);
      }
      setTripDetails(trip_list);
    } catch (e) {
      // offset -= 10;
      console.error(e);
      offset === 0 ? (offset = 0) : (offset -= 10);
      setNext(true);
    }
  };
  const handlePrevious = async () => {
    setNext(false);
    if (offset === 0) {
      offset = offset;
    } else offset -= 10;
    await getTripList(user, offset);
  };
  const handleNext = async () => {
    offset += 10;
    await getTripList(user, offset);
  };
  useEffect(() => {
    offset = 0;
    Auth.currentAuthenticatedUser().then(result => {
      setUser(result.username);
      getTripList(result.username);
    });
  }, []);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  startIcon={<CachedIcon />}
                  onClick={() => getTripList(user)}
                >
                  Refresh
                </Button>
                <Typography variant="body1" style={{ color: '#938989' }}>
                  Last refresh at {moment().format('MMMM Do YYYY, h:mm:ss a')}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px ',
            }}
          >
            <Button
              onClick={handlePrevious}
              disabled={offset === 0}
              startIcon={<ArrowBackIcon />}
            >
              Previous
            </Button>
            <Button
              onClick={handleNext}
              endIcon={<ArrowForwardIcon />}
              disabled={next}
            >
              Next
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <RequestsListView
            tripDetails={tripDetails}
            userId={user}
            triggerRefresh={() => getTripList(user)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ESPRequest;
