/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-var */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import TransactionListView from './BalanceRequests/TransactionListView';
import apiCaller from '../../utils/apiCallerESP';
import CustomSelect from './BalanceRequests/CustomSelect';

const BalanceRequests = () => {
  const [balanceRequest, setBalanceRequest] = useState();
  const [fetching, setFetching] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [next, setNext] = useState(false);
  const [message, setMessage] = React.useState('');
  const getTransactionList = async (value, offset) => {
    setFetching(true);
    const endPoint = 'balancerequestlist-esp';
    const body = {
      limit: '10',
      offset: offset || 0,
      transaction_status: value !== 'all' ? value : null,
    };
    try {
      const response = await apiCaller(endPoint, body);

      const requests = response.data.body;
      if (requests.length < 10) {
        setNext(true);
      }
      setBalanceRequest(requests);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      setNext(true);
      setBalanceRequest();
      setMessage('No Records Found');
      setOpen(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handlePagination = (value, offset, nextToggle) => {
    setNext(nextToggle);
    getTransactionList(value, offset);
  };
  useEffect(() => {
    getTransactionList();
  }, []);
  return (
    <div>
      <CustomSelect
        triggerRefresh={(value, offset, toggle) =>
          handlePagination(value, offset, toggle)
        }
      />
      {fetching ? (
        <CircularProgress />
      ) : (
        <>
          <TransactionListView
            balanceRequest={balanceRequest}
            triggerRefresh={(value, offset) =>
              getTransactionList(value, offset)
            }
            next={next}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </>
      )}
    </div>
  );
};

export default BalanceRequests;
