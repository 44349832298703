/* eslint-disable no-self-assign */
/* eslint-disable no-var */
import {
  Grid,
  // TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Auth } from 'aws-amplify';
import CachedIcon from '@material-ui/icons/Cached';
import apiCaller from '../../utils/apiCallerESP';
import RequestsListView from './BrokerRequests/RequestsListView';

var offset = 0;
const BrokerRequest = () => {
  const [fetching, setFetching] = React.useState(false);
  const [user, setUser] = React.useState();
  const [brokerDetails, setBrokerDetails] = React.useState();
  const [next, setNext] = React.useState(false);

  const getBrokerList = async (userID, Offset) => {
    const endPoint = 'broker-list';
    const data = {
      transporter_id: userID,
      limit: '10',
      offset: Offset || 0,
    };
    try {
      const response = await apiCaller(endPoint, data);
      const { agentsList } = response.data.body;
      if (agentsList.length < 10) {
        setNext(true);
      }
      setBrokerDetails(agentsList);
      setFetching(false);
    } catch (e) {
      console.error(e);
      offset === 0 ? (offset = 0) : (offset -= 10);
      setNext(true);
      setFetching(false);
    }
  };
  const handlePrevious = async () => {
    setNext(false);
    if (offset === 0) {
      offset = offset;
    } else offset -= 10;
    await getBrokerList(user, offset);
  };
  const handleNext = async () => {
    offset += 10;
    await getBrokerList(user, offset);
  };
  useEffect(() => {
    offset = 0;
    setFetching(true);
    Auth.currentAuthenticatedUser().then(result => {
      setUser(result.username);
      getBrokerList(result.username);
    });
  }, []);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  startIcon={<CachedIcon />}
                  onClick={() => getBrokerList(user)}
                >
                  Refresh
                </Button>
                <Typography variant="body1" style={{ color: '#938989' }}>
                  Last refresh at {moment().format('MMMM Do YYYY, h:mm:ss a')}
                </Typography>
              </div>
            </Grid>
            {/* <Grid item>
              <TextField
                id="outlined-basic"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Broker"
              />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px ',
            }}
          >
            <Button
              onClick={handlePrevious}
              disabled={offset === 0}
              startIcon={<ArrowBackIcon />}
            >
              Previous
            </Button>
            <Button
              onClick={handleNext}
              endIcon={<ArrowForwardIcon />}
              disabled={next}
            >
              Next
            </Button>
          </div>
        </Grid>
        {fetching ? (
          <CircularProgress />
        ) : (
          <Grid item xs={12}>
            <RequestsListView
              fetching={fetching}
              brokerDetails={brokerDetails}
              triggerRefresh={() => {
                getBrokerList(user);
              }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default BrokerRequest;
