/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
// import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import apiCaller from '../../utils/apiCallerESP';
import TransporterListView from './TransporterManagement/TransporterListView';

var lastEval = [];
const TransporterManagement = () => {
  const [transporterData, setTransporterData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [nextKey, setNextKey] = useState('');
  const [nextDisabled, setNextDisabled] = useState(false);
  const [previousDisabled, setPreviousDisabled] = useState(true);
  const getTranporterList = async (key, previous) => {
    const endPoint = 'esp-premium-transporter-list';
    if (previous) {
      setNextDisabled(false);
      lastEval.pop();
      const lastElement = lastEval[lastEval.length - 1];
      const body = {
        total_items: 10,
        last_evaluated_key: lastElement || null,
      };
      try {
        const response = await apiCaller(endPoint, body);

        const data = response.data.items;
        const lastEvalKey = response.data.LastEvaluatedKey.lastkey;
        setNextKey(lastEvalKey);
        if (lastEval.length === 0) {
          lastEval.push(lastEvalKey);
          setPreviousDisabled(true);
        }

        setTransporterData(data);
        setFetching(false);
        return response;
      } catch (e) {
        setFetching(false);
      }
    } else if (!previous) {
      if (key) {
        setPreviousDisabled(false);
      }

      setNextDisabled(false);
      const body = {
        total_items: 10,
        last_evaluated_key: key || null,
      };
      try {
        const response = await apiCaller(endPoint, body);
        const data = response.data.items;
        const lastEvalKey = response.data.LastEvaluatedKey?.lastkey;

        setNextKey(lastEvalKey);
        setTransporterData(data);
        if (!lastEval.includes(lastEvalKey)) {
          lastEval.push(lastEvalKey);
        }
        if (data.length < 10) {
          setNextDisabled(true);
          lastEval.pop();
        }
        setFetching(false);
        return response;
      } catch (e) {
        setFetching(false);
      }
    }
  };
  const handleRefresh = () => {
    lastEval = [];
    setPreviousDisabled(true);
    getTranporterList();
  };

  useEffect(() => {
    lastEval = [];
    setFetching(true);
    getTranporterList();
  }, []);
  return (
    <div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <div>
          <TransporterListView
            transporterData={transporterData}
            fetching={fetching}
            triggerRefresh={(key, previous) => getTranporterList(key, previous)}
            triggerInitialRefresh={() => handleRefresh()}
            nextKey={nextKey}
            nextButtonDisabled={nextDisabled}
            previousDisabled={previousDisabled}
            evalKeys={lastEval}
          />
        </div>
      )}
    </div>
  );
};

export default TransporterManagement;
