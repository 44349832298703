/* eslint-disable no-var */
/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Grid,
  Typography,
  DialogContent,
  CircularProgress,
  // CircularProgress,
  // Divider,
} from '@material-ui/core';
import transportermanagementicon from '../../../assets/img/espicons/transportermanagementicon.png';
import apiCaller from '../../../utils/apiCallerESP';

var ESPDetails = [];
const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // height: '760px',
  margin: '20px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  viewButton: {
    backgroundColor: '#3366FF',
    color: 'white',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '10px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  label: { fontWeight: 'bold' },
  value: { marginBottom: '10px' },
}));

export default function MaxWidthDialog({
  handleToggle,
  transporterData,
  selectedTransporterName,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  // const [espDetails, setESPDetails] = React.useState([]);
  const { user_id } = transporterData;

  const getUserEspDetail = async () => {
    setFetching(true);

    const body = {
      transporter_id: user_id,
    };
    const endPoint = 'view-esp-accounts';
    try {
      const response = await apiCaller(endPoint, body);
      const details = response.data.body;
      // setESPDetails(details);
      for (const key in details) {
        const duplicate = ESPDetails.some(
          item => item.account_id === details[key].account_id
        );
        if (!duplicate) {
          ESPDetails.push(details[key]);
        }
      }
      setFetching(false);
      setOpen(true);
    } catch (e) {
      console.log(e);
      setFetching(false);
    }
  };
  const handleClickOpen = () => {
    ESPDetails = [];
    getUserEspDetail();
  };
  const handleClickStatement = (id, name) => {
    handleToggle(id);
    selectedTransporterName(name);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return curr;
    }
    return 0;
  };

  return (
    <React.Fragment>
      {fetching ? (
        <CircularProgress />
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.viewButton}
          startIcon={<VisibilityIcon />}
          size="small"
          onClick={handleClickOpen}
        >
          View
        </Button>
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        <DialogContent>
          <fieldset style={shifter}>
            <legend style={{ maxWidth: '400px' }}>
              <div style={avatarContainer}>
                <div style={avatar}>
                  <img src={transportermanagementicon} alt="icon" />
                </div>
                <Typography variant="h6" gutterBottom>
                  Transporter Details
                </Typography>
              </div>
            </legend>
            <Grid container spacing={2} justify="space-between">
              <Grid item xs={6}>
                <Typography variant="body1" className={classes.label}>
                  Transporter Name
                </Typography>
                <Typography variant="body2">
                  {transporterData.company_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" className={classes.label}>
                  User Name
                </Typography>
                <Typography variant="body2">
                  {transporterData.user_id}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}> */}
            {ESPDetails.map(detail => (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body1" className={classes.label}>
                    ESP Account ID
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {detail.account_id}
                  </Typography>

                  {/* <Typography variant="h6">{detail.balance}</Typography> */}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" className={classes.label}>
                    Number Of Txn
                  </Typography>
                  <Typography variant="body1" className={classes.value}>
                    {detail.number_of_transactions
                      ? detail.number_of_transactions
                      : 0}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h4">
                        {toIndianCurrency(detail.balance)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickStatement(
                            detail.account_id,
                            transporterData.company_name
                          )
                        }
                        variant="contained"
                      >
                        View Statement
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {/* </Grid> */}
          </fieldset>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
