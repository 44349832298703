/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, makeStyles, MenuItem, Select } from '@material-ui/core';
import BankIllustration from '../../../../assets/img/Bank Illustration.png';
import apiCaller from '../../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  // borderRadius: '7px',
  height: '760px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};

const profileImageContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '10px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  filter: { minWidth: '150px', height: '20px' },
  viewButton: {
    backgroundColor: '#3366FF',
    color: 'white',
  },
}));
const Personaldetails = ({
  submit,
  tripDetails,
  userId,
  selectedAccountId,
  rejectionESP,
  approvedAmount,
  status,
  active,
  tripData,
}) => {
  const classes = useStyles();
  const [account, setAccount] = React.useState('');
  const [espaccounts, setESPAccounts] = React.useState();
  const [remark, setRemark] = React.useState('');
  const { approved_balance } = tripData;
  const handleChange = event => {
    setAccount(event.target.value);
    selectedAccountId(event.target.value);
  };
  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return curr;
    }
    return 0;
  };
  const getESPAccounts = async () => {
    // eslint-disable-next-line prefer-const
    let espAccounts = [];
    const data = { transporter_id: userId };
    const endPoint = 'view-esp-accounts';
    const response = await apiCaller(endPoint, data);
    const { body } = response.data;
    for (let keys in body) {
      espAccounts.push(body[keys].account_id);
    }
    if (espAccounts.length === 1) {
      setAccount(espAccounts[0]);
      selectedAccountId(espAccounts[0]);
    }
    setESPAccounts(espAccounts);
  };
  const handleActive = value => {
    active(value);
  };
  useEffect(() => {
    getESPAccounts();
  }, []);
  return (
    <div>
      <DialogContent className={classes.DialogContent}>
        <fieldset style={shifter}>
          <legend style={{ maxWidth: '400px' }}>
            <div style={avatarContainer}>
              <div style={avatar}>
                <AccountBalanceIcon style={{ color: 'white' }} />
              </div>
              <Typography variant="h6" gutterBottom>
                Account Details
              </Typography>
            </div>
          </legend>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div style={profileImageContainer}>
                <img
                  src={BankIllustration}
                  style={{ objectFit: 'fill', width: '400px' }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">Account Name :</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">
                      {tripDetails?.account_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">
                      Account Number :
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">
                      {tripDetails?.account_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">Bank Name :</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">
                      {tripDetails?.bank_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">ISFC Code :</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">
                      {tripDetails?.ifsc_code}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">
                      Approved Amount :
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="subtitle2">
                      {status === 'approved'
                        ? toIndianCurrency(approved_balance)
                        : approvedAmount}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">ESP Account :</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    {espaccounts?.length === 1 ? (
                      <Typography variant="subtitle2">
                        {espaccounts[0]}
                      </Typography>
                    ) : (
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={account}
                        onChange={handleChange}
                        className={classes.filter}
                      >
                        {espaccounts?.map(Account => (
                          <MenuItem value={Account}>{Account}</MenuItem>
                        ))}
                      </Select>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <textarea
                      style={{
                        padding: '10px',
                        width: '100%',
                        // marginBottom: '5px',
                        marginTop: '14px',
                        borderRadius: '5px',
                        border: '1px solid lightgray',
                      }}
                      placeholder="Remarks"
                      rows={1}
                      onChange={event => setRemark(event.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={rejectionESP}
                      disabled={
                        remark === '' ||
                        status === 'approved' ||
                        status === 'rejected'
                      }
                    >
                      Reject
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </fieldset>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            margin: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleActive(3)}
            className={classes.button}
            startIcon={<ArrowBackIcon />}
            // size="small"
          >
            Back
          </Button>
          <Button
            onClick={submit}
            // size="small"
            color="primary"
            variant="contained"
            className={classes.viewButton}
            endIcon={<CheckIcon />}
            disabled={
              account === '' || status === 'approved' || status === 'rejected'
            }
          >
            Approve
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default Personaldetails;
