/* eslint-disable import/no-mutable-exports */
import Dashboard from 'views/Dashboard/Dashboard.jsx';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import WalletList from '../views/Wallet/WalletList';
import WalletBalance from '../views/Wallet/SuperAdminWalletList';
import AgentActivation from '../views/SuperAdminESP/AgentActivation';
import TransporterManagement from '../views/SuperAdminESP/TransporterManagement';
import BalanceRequests from '../views/SuperAdminESP/BalanceRequests';
import Accounts from '../views/SuperAdminESP/Accounts';

const superAdminRoutes = {
  agentRoutes: [
    {
      path: '/super_admin/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      redirect: true,
      path: '/super_admin',
      pathTo: '/super_admin/dashboard',
      name: 'Dashboard',
    },
  ],
  superAdminRoutes: [
    {
      path: '/super_admin/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      path: '/super_admin/account_balance',
      name: 'Account Balance',
      icon: LocalAtmIcon,
      component: WalletBalance,
    },
    {
      path: '/super_admin/account_view',
      name: 'View Account',
      icon: AccountBalanceWallet,
      component: WalletList,
    },
    {
      collapse: true,
      name: 'ESP Management',
      icon: AccountBalanceIcon,
      state: 'openESP',
      views: [
        {
          path: '/super_admin/ESP/AgentActivation',
          name: 'Agent Activation',
          mini: 'A',
          component: AgentActivation,
        },
        {
          path: '/super_admin/ESP/transporter-management',
          name: 'Transporter Management',
          mini: 'T',
          component: TransporterManagement,
        },
        {
          path: '/super_admin/ESP/balance-request',
          name: 'Balance Payments Requests',
          mini: 'B',
          component: BalanceRequests,
        },
        {
          path: '/super_admin/ESP/accounts',
          name: 'Accounts',
          mini: 'A',
          component: Accounts,
        },
      ],
    },
    {
      redirect: true,
      path: '/super_admin',
      pathTo: '/super_admin/dashboard',
      name: 'Dashboard',
    },
  ],
};

export default superAdminRoutes;
