/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import swal from '@sweetalert/with-react';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Button, DialogContent, Grid } from '@material-ui/core';
import apiCaller from '../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  margin: '20px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '10px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  info: {
    display: 'flex',
    alignContent: 'baseline',
    margin: '10px',
    justifyContent: 'space-between',
  },
  TextField: {
    border: '1px solid lightgray',
    borderRadius: '4px',
    padding: ' 2px',
    // marginLeft: '10px',
  },
  footerAction: { display: 'flex', justifyContent: 'center', margin: '30px' },
  cta: { backgroundColor: '#3366FF', color: '#FFFFFF' },
  label: { fontWeight: 'bold' },
  enabledButton: {
    backgroundColor: '#039400',
    color: 'white',
    borderRadius: '25px',
    width: '60px',
    fontSize: '10px',
  },
  disabledButton: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '25px',
    width: '60px',
    fontSize: '10px',
  },
}));

export default function MaxWidthDialog({ data, triggerRefresh }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [accountname, setAccountName] = React.useState('');
  const [espLimit, setESPLimit] = React.useState('');
  const [valid, setValid] = React.useState({ name: true, limit: false });
  // const [alert, setAlert] = React.useState(false);

  const { user_id } = data;

  const handleClickOpen = event => {
    if (!data.esp_enabled) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleESPLimit = event => {
    const regex = /^[0-9][A-Za-z0-9 -]*$/;
    const test = regex.test(event.target.value);
    if (test) {
      setValid({ limit: true });
      setESPLimit(event.target.value);
    } else {
      setValid({ limit: false });
    }
  };
  const handleAccountName = event => {
    setAccountName(event.target.value);
  };
  const handleEnableESP = async () => {
    const body = {
      account_name: accountname,
      transporter_id: user_id,
      balance: espLimit,
      source: 'lobb_pay',
    };
    const endPoint = 'admin-enable-esp';
    try {
      await apiCaller(endPoint, body);
      swal({
        title: 'Transporter Approved!',
        text: `You Approved ${user_id} Transporter for ESP!`,
        icon: 'success',
        timer: 2000,
        button: false,
      }).then(() => {
        triggerRefresh();
        setOpen(false);
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      {data.esp_enabled ? (
        <Button
          className={classes.enabledButton}
          size="small"
          variant="raised"
          onClick={handleClickOpen}
          endIcon={<Brightness1Icon style={{ fontSize: 20 }} />}
        >
          ON
        </Button>
      ) : (
        <Button
          className={classes.disabledButton}
          size="small"
          variant="raised"
          onClick={handleClickOpen}
          startIcon={<Brightness1Icon style={{ fontSize: 20 }} />}
        >
          OFF
        </Button>
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        <DialogContent>
          <fieldset style={shifter}>
            <legend style={{ maxWidth: '400px' }}>
              <div style={avatarContainer}>
                <div style={avatar}>
                  <AccountBalanceIcon style={{ color: 'white' }} />
                </div>
                <Typography variant="h6" gutterBottom>
                  Personal Details
                </Typography>
              </div>
            </legend>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {' '}
                <Typography variant="body2" className={classes.label}>
                  Transporter Name :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{data.company_name}</Typography>
              </Grid>
              <Grid item xs={6}>
                {' '}
                <Typography variant="body2" className={classes.label}>
                  Account Name :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <input
                  type="text"
                  className={classes.TextField}
                  onChange={handleAccountName}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className={classes.label}>
                  ESP Account ID :{' '}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{data.company_user_id}</Typography>
              </Grid>
              <Grid item xs={6}>
                {' '}
                <Typography variant="body2" className={classes.label}>
                  ESP Limit :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <input
                  type="text"
                  className={classes.TextField}
                  onChange={handleESPLimit}
                />
              </Grid>
            </Grid>

            <div className={classes.footerAction}>
              <Button
                className={classes.cta}
                size="small"
                onClick={handleEnableESP}
                disabled={!valid.limit}
              >
                Confirm
              </Button>
            </div>
          </fieldset>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
