import React, { useState, useEffect } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import AccountsListView from './AccountsManagement/AccountsListView';
import apiCaller from '../../utils/apiCallerESP';

const Accounts = () => {
  const [transporterData, setTransporterData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [next, setNext] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const getTranporterList = async offset => {
    setFetching(true);
    const endPoint = 'view-all-esp-accounts';
    const body = {
      limit: 10,
      offset: offset || 0,
    };
    try {
      const response = await apiCaller(endPoint, body);
      const data = response.data.body;
      if (data.length < 10) {
        setNext(true);
      }
      setTransporterData(data);
      setFetching(false);
    } catch (e) {
      setFetching(false);
      setNext(true);
      setMessage('No Records Found');
      setOpen(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handlePagination = (offset, nextToggle) => {
    setNext(nextToggle);
    getTranporterList(offset);
  };
  useEffect(() => {
    getTranporterList();
  }, []);
  return (
    <div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <div>
          <AccountsListView
            transporterData={transporterData}
            fetching={fetching}
            triggerRefresh={(offset, toggle) =>
              handlePagination(offset, toggle)
            }
            next={next}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </div>
      )}
    </div>
  );
};

export default Accounts;
