/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Auth } from 'aws-amplify';
import swal from '@sweetalert/with-react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';
import Personaldetails from './MultiStep/Personaldetails';
import TripDetails from './MultiStep/TripDetails';
import PODdetails from './MultiStep/PODDetails';
import EwayBill from './MultiStep/EwayBill';
import AccountBalance from './MultiStep/AccountDetails';
import apiCaller from '../../../utils/apiCallerESP';

const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  viewButton: {
    backgroundColor: '#3366FF',
    color: 'white',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '10px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
}));

export default function MaxWidthDialog({
  status,
  tripID,
  userId,
  balance,
  triggerRefresh,
  tripData,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);
  // const [user, setUser] = React.useState();
  const [approvedAmount, setApprovedAmount] = React.useState('');
  const [personalRemarks, setPersonalRemarks] = React.useState('');
  const [podRemarks, setPODRemarks] = React.useState('');
  const [tripRemarks, setTripRemarks] = React.useState('');
  const [ewayRemarks, setEwayRemarks] = React.useState('');
  const [espAccount, setESPAccount] = React.useState();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const [details, setDetails] = React.useState();
  const getTripDetails = async () => {
    setFetching(true);
    const endPoint = 'esp-trip-detail';
    const data = {
      trip_id: tripID,
    };
    try {
      const response = await apiCaller(endPoint, data);
      const extractedData = response.data.body.trip_detail;
      setDetails(extractedData);
      setFetching(false);
      setOpen(true);
    } catch (error) {
      setFetching(false);
      setOpenSnackbar(true);
    }
  };
  const handleClickOpen = () => {
    setActive(0);
    setPersonalRemarks('');
    setPODRemarks('');
    setTripRemarks('');
    setEwayRemarks('');
    Auth.currentAuthenticatedUser().then(result => {
      // setUser(result);
    });
    getTripDetails();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const ESPRequestReject = async value => {
    const body = {
      trip_id: tripID,
      transporter_id: userId,
      approval_status: false,
      approved_balance: approvedAmount,
      account_number: espAccount,
      trip_balance: balance,
      trip_detail_remark: tripRemarks,
      eway_bill_remark: ewayRemarks,
      pod_remark: podRemarks,
      remark: personalRemarks,
    };
    const endpoint = 'esp-balance-request';
    await apiCaller(endpoint, body);
    triggerRefresh();
    setOpen(false);
  };

  const ESPRequestSubmit = async () => {
    const body = {
      trip_id: tripID,
      transporter_id: userId,
      approval_status: true,
      approved_balance: approvedAmount,
      account_number: espAccount,
      trip_balance: balance,
      trip_detail_remark: tripRemarks,
      eway_bill_remark: ewayRemarks,
      pod_remark: podRemarks,
      remark: personalRemarks,
    };
    const endpoint = 'esp-balance-request';
    try {
      await apiCaller(endpoint, body);
      swal({
        title: 'ESP request Approval!',
        text: `You approved ESP request for ${details?.agent_name} agent for ₹${approvedAmount}!`,
        icon: 'success',
        timer: 2000,
        button: false,
      }).then(() => {
        triggerRefresh();
        setOpen(false);
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      {fetching ? (
        <CircularProgress />
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.viewButton}
          startIcon={<VisibilityIcon />}
          size="small"
          onClick={handleClickOpen}
        >
          View
        </Button>
      )}

      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        {active == 0 ? (
          <Personaldetails
            active={value => setActive(value)}
            tripDetails={details}
            remarks={value => setPersonalRemarks(value)}
            rejectionESP={() => ESPRequestReject()}
            status={status}
            tripData={tripData}
          />
        ) : active == 1 ? (
          <TripDetails
            active={value => setActive(value)}
            tripDetails={details}
            remarks={value => setTripRemarks(value)}
            approvedAmount={value => setApprovedAmount(value)}
            approvedAmountRecieved={approvedAmount}
            rejectionESP={() => ESPRequestReject()}
            status={status}
            tripData={tripData}
          />
        ) : active == 2 ? (
          <PODdetails
            active={value => setActive(value)}
            tripDetails={details}
            remarks={value => setPODRemarks(value)}
            rejectionESP={() => ESPRequestReject()}
            status={status}
            tripData={tripData}
          />
        ) : active == 3 ? (
          <EwayBill
            active={value => setActive(value)}
            tripDetails={details}
            remarks={value => setEwayRemarks(value)}
            rejectionESP={() => ESPRequestReject()}
            status={status}
            tripData={tripData}
          />
        ) : active == 4 ? (
          <AccountBalance
            active={value => setActive(value)}
            tripDetails={details}
            userId={userId}
            submit={() => ESPRequestSubmit()}
            selectedAccountId={value => setESPAccount(value)}
            rejectionESP={() => ESPRequestReject()}
            approvedAmount={approvedAmount}
            status={status}
            tripData={tripData}
          />
        ) : (
          ''
        )}
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="No details available"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}
