/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Button, DialogContent, Grid } from '@material-ui/core';
import tripicon from '../../../assets/img/espicons/tripicon.png';
import panaImage from '../../../assets/img/pana.svg';
import apiCaller from '../../../utils/apiCallerESP';

const shifter = {
  position: 'relative',
  padding: '15px',
  paddingLeft: '30px',
  paddingRight: '30px',
  border: 'solid lightgray 2px',
  margin: '0px 0px 20px 0px',
  backgroundColor: 'white',
};
const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
  // background: 'white',

  padding: '2px 10px',
  // borderRadius: '30px 30px 0px 0px',
};
const avatar = {
  background: '#163BAC',
  padding: '12px',
  borderRadius: '50%',
  margin: '10px',
};
const useStyles = makeStyles(theme => ({
  Dialog: {
    padding: '10px',
  },
  DialogContent: { minHeight: '500px' },
  profileAction: { marginTop: '10px' },
  dialogActions: { display: 'flex', alignContent: 'center' },
  info: { display: 'flex', flexDirection: 'column' },
  TextField: {
    border: '1px solid lightgray',
    borderRadius: '4px',
    padding: ' 2px',
    marginBottom: '12px',
    width: '220px',
  },
  footerAction: { display: 'flex', justifyContent: 'center', margin: '30px' },
  cta: { backgroundColor: '#3366FF', color: '#FFFFFF', marginLeft: '10px' },
  label: { fontWeight: 'bold', margin: '10px' },
  tripcontainer: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
  },
  viewButton: {
    backgroundColor: '#3366FF',
    color: 'white',
  },
  select: { width: '200px' },
  value: { margin: '10px' },
}));

export default function MaxWidthDialog({ data }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [trip, setTrip] = React.useState();
  const { trip_id } = data;
  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return curr;
    }
    return 0;
  };
  const getTripDetails = async () => {
    const endPoint = 'get-trip-details';
    const body = {
      trip_id,
    };

    try {
      const response = await apiCaller(endPoint, body);
      const { trip_details } = response.data.body;
      setTrip(trip_details);
    } catch (e) {
      console.log(e);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
    getTripDetails();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.viewButton}
        startIcon={<VisibilityIcon />}
        size="small"
        onClick={handleClickOpen}
      >
        View
      </Button>

      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.Dialog}
      >
        <DialogContent>
          <fieldset style={shifter}>
            <legend style={{ maxWidth: '400px' }}>
              <div style={avatarContainer}>
                <div style={avatar}>
                  <img src={tripicon} alt="tripicon" />
                </div>
                <Typography variant="h6" gutterBottom>
                  Trip Details
                </Typography>
              </div>
            </legend>
            <Grid container>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      From City :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.value}>
                      {trip ? trip?.from_city : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      To City :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.value}>
                      {trip ? trip?.to_city : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      Truck Number :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.value}>
                      {trip ? trip?.truck_number : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      Truck Type :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.value}>
                      {trip ? trip?.truck_type : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      Freight Value :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.value}>
                      {trip ? toIndianCurrency(trip?.freight_value) : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      Advance :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.value}>
                      {trip ? toIndianCurrency(trip?.advance) : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.label}>
                      Approved Balance :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.value}>
                      {trip ? toIndianCurrency(trip?.approved_balance) : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={panaImage} />
                </div>
              </Grid>
            </Grid>
          </fieldset>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
