/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable guard-for-in */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import moment from 'moment';
import CachedIcon from '@material-ui/icons/Cached';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Button, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EnableESPModal from './EnableESPModal';
import SingleTransporterDetailsDialog from './SingleTransporterDetailsDialog';
import ESPStatement from './ESPStatement';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableHeadCell: { color: 'white' },
  tableBodyRow: { height: '50px' },
});

function createData(
  transporterName,
  userId,
  wallet,
  espStatus,
  account,
  enabled
) {
  return { transporterName, userId, wallet, espStatus, account, enabled };
}

export default function TransporterListView({
  evalKeys,
  transporterData,
  triggerRefresh,
  nextKey,
  nextButtonDisabled,
  previousDisabled,
  triggerInitialRefresh,
}) {
  const [toggle, setToggle] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [selectedTransporterName, setSelectedTransporterName] = useState('');
  const classes = useStyles();

  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
      });
      return curr;
    }
    return 0;
  };
  const rows = transporterData?.map(data =>
    createData(
      data?.company_name,
      data?.user_id,
      toIndianCurrency(data?.wallet),
      data,
      data,
      data.esp_enabled
    )
  );

  const handleToggle = async id => {
    setSelectedAccountId(id);
    setToggle(true);
  };
  const handlePrevious = async () => {
    await triggerRefresh(evalKeys, true);
  };
  const handleNext = async () => {
    await triggerRefresh(nextKey, false);
  };
  const handleToggleBack = () => {
    setToggle(false);
  };
  return (
    <div>
      {!toggle ? (
        <div style={{ margin: '20px 0px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              startIcon={<CachedIcon />}
              onClick={() => triggerInitialRefresh()}
            >
              Refresh
            </Button>
            <Typography variant="body1" style={{ color: 'gray' }}>
              Last refresh at {moment().format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '20px 0px',
            }}
          >
            <Button
              disabled={previousDisabled}
              onClick={handlePrevious}
              startIcon={<ArrowBackIcon />}
            >
              Previous
            </Button>
            <Button
              onClick={handleNext}
              disabled={nextButtonDisabled}
              endIcon={<ArrowForwardIcon />}
            >
              Next
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell className={classes.tableHeadCell}>
                        Transporter Name
                      </TableCell>
                      <TableCell className={classes.tableHeadCell} align="left">
                        User ID
                      </TableCell>
                      <TableCell
                        className={classes.tableHeadCell}
                        align="center"
                      >
                        ESP Status
                      </TableCell>
                      <TableCell
                        className={classes.tableHeadCell}
                        align="center"
                      >
                        Details
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map(row => (
                      <TableRow className={classes.tableBodyRow} key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.transporterName}
                        </TableCell>
                        <TableCell align="left">{row.userId}</TableCell>
                        {/* <TableCell align="left" />
                  <TableCell align="right" /> */}
                        <TableCell align="center">
                          <EnableESPModal
                            data={row.espStatus}
                            triggerRefresh={triggerRefresh}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {row.enabled ? (
                            <SingleTransporterDetailsDialog
                              handleToggle={value => handleToggle(value)}
                              transporterData={row.account}
                              selectedTransporterName={value =>
                                setSelectedTransporterName(value)
                              }
                            />
                          ) : null}

                          {/* <CheckMap handleToggle={value => setToggle(value)} /> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      ) : (
        <ESPStatement
          selectedAccountId={selectedAccountId}
          // transactionData={statementDetails}
          selectedTransporterName={selectedTransporterName}
          handleToggleBack={value => handleToggleBack(value)}
        />
      )}
    </div>
  );
}
