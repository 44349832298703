import React from 'react';
import LensIcon from '@material-ui/icons/Lens';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CachedIcon from '@material-ui/icons/Cached';
import moment from 'moment';

const useStyles = makeStyles({
  buttonGroup: { fontWeight: 'lighter' },
  activeButton: {
    border: '1px solid blue',
    backgroundColor: 'lightgray',
    fontWeight: 'bold',
    // color: 'white',
  },
});
const buttonFIlters = [
  {
    id: 'All',
    value: 'all',
    LensIcon: <LensIcon style={{ fontSize: 10, color: 'black' }} />,
  },
  {
    id: 'Processing',
    value: 'processing',
    LensIcon: <LensIcon style={{ fontSize: 10, color: '#3366FF' }} />,
  },
  // {
  //   id: 'processed',
  //   value: 'processed',
  //   LensIcon: <LensIcon style={{ fontSize: 10, color: '#FF7E03' }} />,
  // },
  {
    id: 'Success',
    value: 'processed',
    LensIcon: <LensIcon style={{ fontSize: 10, color: '#00811C' }} />,
  },
  {
    id: 'Reversed',
    value: 'reversed',
    LensIcon: <LensIcon style={{ fontSize: 10, color: '#FF0000' }} />,
  },
];
const CustomSelect = ({ triggerRefresh }) => {
  const [activeFilter, setActiveFilter] = React.useState('');
  const handleFilterList = value => {
    setActiveFilter(value);
    triggerRefresh(value);
  };
  const classes = useStyles();
  return (
    <div
      style={{
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <ButtonGroup disableElevation variant="outlined">
          {buttonFIlters.map(filter => (
            <Button
              variant="outlined"
              startIcon={filter.LensIcon}
              onClick={() => handleFilterList(filter.value)}
              className={
                activeFilter === filter.value
                  ? classes.activeButton
                  : classes.buttonGroup
              }
              disableElevation
            >
              {filter.id}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          startIcon={<CachedIcon />}
          onClick={() => handleFilterList('all')}
        >
          Refresh
        </Button>
        <Typography variant="body1" style={{ color: '#938989' }}>
          Last refresh at {moment().format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </div>
    </div>
  );
};

export default CustomSelect;
