/* eslint-disable no-self-assign */
/* eslint-disable no-var */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CallMadeSharpIcon from '@material-ui/icons/CallMadeSharp';
import CallReceivedSharpIcon from '@material-ui/icons/CallReceivedSharp';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import apiCaller from '../../../utils/apiCallerESP';

var offset = 0;
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableCell: { color: 'white' },
  tableBodyCell: { height: '60px' },
});

function createData(
  transactionSerial,
  amount,
  accountNo,
  agent,
  truckNo,
  date,
  utr,
  status,
  nature
) {
  return {
    transactionSerial,
    amount,
    accountNo,
    agent,
    truckNo,
    date,
    utr,
    status,
    nature,
  };
}

export default function DenseTable({ id }) {
  const classes = useStyles();
  const [transactions, setTransactions] = useState();
  const [fetching, setFetching] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const convertTimestamp = value => {
    const date = new Date(value);
    const convertedDate = `${date.getDate()}/${date.getMonth() +
      1}/${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    return convertedDate;
  };
  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return curr;
    }
    return 0;
  };
  const rows = transactions?.map(transaction =>
    createData(
      transaction.transaction_id,
      toIndianCurrency(transaction.amount),
      transaction.account,
      transaction.agent,
      transaction.truck,
      convertTimestamp(transaction.date),
      transaction.utr,
      transaction.status,
      transaction.nature
    )
  );

  const getAccountStatement = async () => {
    setFetching(true);
    const endPoint = 'view-transporters-statement';
    const body = { account_id: id, limit: '10', offset };
    const response = await apiCaller(endPoint, body);
    const data = response.data.body.transactions;
    if (data.length != 0) {
      setTransactions(data);
      if (data.length < 10) {
        setNextDisabled(true);
      }
      setFetching(false);
    } else {
      setNextDisabled(true);
      setFetching(false);
    }
  };
  const handlePrevious = async () => {
    setNextDisabled(false);
    if (offset === 0) {
      offset = offset;
      getAccountStatement();
      // triggerRefresh(offset);
    } else {
      offset -= 10;
      getAccountStatement();
      // triggerRefresh(offset);
    }
  };
  const handleNext = async () => {
    offset += 10;
    getAccountStatement();
    // triggerRefresh(offset);
  };
  useEffect(() => {
    offset = 0;
    getAccountStatement();
  }, []);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0px',
        }}
      >
        <Button
          onClick={handlePrevious}
          disabled={offset === 0}
          startIcon={<ArrowBackIcon />}
        >
          Previous
        </Button>
        <Button
          onClick={handleNext}
          disabled={nextDisabled}
          endIcon={<ArrowForwardIcon />}
        >
          Next
        </Button>
      </div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCell} align="left">
                  Txn #
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  Amount
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Account #
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Agent
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Truck #
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Date
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  UTR
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(row => (
                <TableRow
                  key={row.transactionSerial}
                  className={classes.tableBodyCell}
                >
                  <TableCell component="th" scope="row">
                    {row.transactionSerial}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {row.amount}
                    {row.status !== 'reversed' ? (
                      row.nature == 'debit' ? (
                        <CallMadeSharpIcon style={{ fontSize: 15 }} />
                      ) : (
                        <CallReceivedSharpIcon style={{ fontSize: 15 }} />
                      )
                    ) : null}
                  </TableCell>
                  <TableCell align="center">{row.accountNo}</TableCell>
                  <TableCell align="center">{row.agent}</TableCell>
                  <TableCell align="center">{row.truckNo}</TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="center">{row.utr}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      disableRipple
                      disabled
                      disableFocusRipple
                      style={{
                        borderColor:
                          row.status == 'processing'
                            ? '#FF7E03'
                            : row.status == 'processed'
                            ? 'green'
                            : row.status == 'reversed'
                            ? 'red'
                            : 'black',
                        textTransform: 'capitalize',
                        color:
                          row.status == 'processing'
                            ? '#FF7E03'
                            : row.status == 'processed'
                            ? 'green'
                            : row.status == 'reversed'
                            ? 'red'
                            : 'black',
                        width: '95px',
                      }}
                    >
                      {row.status}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
