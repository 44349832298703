/* eslint-disable no-self-assign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import CallMadeSharpIcon from '@material-ui/icons/CallMadeSharp';
import CallReceivedSharpIcon from '@material-ui/icons/CallReceivedSharp';
import TableRow from '@material-ui/core/TableRow';
import CachedIcon from '@material-ui/icons/Cached';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import apiCaller from '../../../utils/apiCallerESP';

var offset = 0;
var statementDetails = [];
const filterContainer = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-evenly',
};
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableHeadCell: { color: 'white' },
  tableBodyRow: { height: '50px' },
  downloadButton: {
    backgroundColor: '#163BAC',
    color: 'white',
    marginLeft: '10px',
  },
});

function createData(
  transactionNumber,
  amount,
  accountId,
  agent,
  truckNumber,
  timeStamp,
  utr,
  status,
  nature
) {
  return {
    transactionNumber,
    amount,
    accountId,
    agent,
    truckNumber,
    timeStamp,
    utr,
    status,
    nature,
  };
}

export default function DenseTable({
  handleToggle,
  selectedAccountId,
  transactionData,
  handleClickBack,
}) {
  const [balance, setBalance] = useState();
  const [fetching, setFetching] = useState(false);
  const [broker_name, setBrokerName] = useState('');
  const [nextDisabled, setNextDisabled] = useState(false);
  const classes = useStyles();

  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return curr;
    }
    return 0;
  };
  const convertTimestamp = value => {
    const date = new Date(value);
    const convertedDate = `${date.getDate()}/${date.getMonth() +
      1}/${date.getFullYear()}  ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    return convertedDate;
  };
  const rows = statementDetails?.map(data =>
    createData(
      data?.transaction_id,
      toIndianCurrency(data?.amount),
      data?.account,
      data?.agent,
      data?.truck,
      convertTimestamp(data?.date),
      data?.utr,
      data?.status,
      data?.nature
      // data?.data[2]
    )
  );
  const handleExcelDownload = async () => {
    const body = {
      account_id: selectedAccountId.toString(),
      statement: true,
      type: 'accounts',
    };
    const endPoint = 'view-transporters-statement';
    try {
      const response = await apiCaller(endPoint, body);
      const { url } = response.data.body;

      window.open(url, '_blank');

      // setFetching(false);
    } catch (e) {
      // setFetching(false);
    }
  };
  const getAccountStatement = async id => {
    statementDetails = [];
    setFetching(true);
    const body = {
      account_id: id.toString(),
      limit: 10,
      offset,
      // broker_name,
      // type: 'accounts',
    };
    const endPoint = 'view-transporters-statement';
    try {
      const response = await apiCaller(endPoint, body);
      const statement = response.data.body.transactions;
      const accountbalance = toIndianCurrency(
        response.data.body.account_balance
      );
      setBalance(accountbalance);
      if (statement.length != 0) {
        for (const key in statement) {
          const duplicate = statementDetails.some(
            item => item.utr === statement[key].utr
          );
          if (!duplicate) {
            statementDetails.push(statement[key]);
          }
          if (statement.length < 10) {
            setNextDisabled(true);
          }
        }
      } else {
        setNextDisabled(true);
      }

      setFetching(false);
    } catch (e) {
      setFetching(false);
    }
  };
  const handlePrevious = async () => {
    setNextDisabled(false);
    if (offset === 0) {
      offset = offset;
      getAccountStatement(selectedAccountId);
      // triggerRefresh(offset);
    } else {
      offset -= 10;
      getAccountStatement(selectedAccountId);
      // triggerRefresh(offset);
    }
  };
  const handleNext = async () => {
    offset += 10;
    getAccountStatement(selectedAccountId);
    // triggerRefresh(offset);
  };

  useEffect(() => {
    offset = 0;
    getAccountStatement(selectedAccountId);
  }, []);
  return (
    <div>
      <div
        style={{
          margin: '20px 0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          variant="outlined"
          onClick={handleClickBack}
        >
          Back
        </Button>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            startIcon={<CachedIcon />}
            onClick={() => getAccountStatement(selectedAccountId)}
          >
            Refresh
          </Button>
          <Typography variant="body1" style={{ color: 'gray' }}>
            Last refresh at {moment().format('MMMM Do YYYY, h:mm:ss a')}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '20px 0px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">Account Balance : </Typography>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            {balance}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            className={classes.downloadButton}
            startIcon={<ArrowDownwardIcon />}
            size="small"
            onClick={handleExcelDownload}
          >
            Download
          </Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0px',
        }}
      >
        <Button
          onClick={handlePrevious}
          disabled={offset === 0}
          startIcon={<ArrowBackIcon />}
        >
          Previous
        </Button>
        <Button
          onClick={handleNext}
          disabled={nextDisabled}
          endIcon={<ArrowForwardIcon />}
        >
          Next
        </Button>
      </div>
      {fetching ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>Txn #</TableCell>
                <TableCell
                  align="right"
                  className={classes.tableHeadCell}
                  // align="left"
                >
                  Amount
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Account #
                </TableCell>
                {/* <TableCell className={classes.tableHeadCell} align="center">
                  Agent
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Truck #
                </TableCell> */}
                <TableCell className={classes.tableHeadCell} align="center">
                  Date
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  UTR
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(row => (
                <TableRow className={classes.tableBodyRow} key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.transactionNumber}
                  </TableCell>
                  <TableCell align="right">
                    {row.amount}
                    {row.status !== 'reversed' ? (
                      row.nature == 'debit' ? (
                        <CallMadeSharpIcon style={{ fontSize: 15 }} />
                      ) : (
                        <CallReceivedSharpIcon style={{ fontSize: 15 }} />
                      )
                    ) : null}
                  </TableCell>
                  <TableCell align="center">{row.accountId}</TableCell>
                  {/* <TableCell align="center">{row.agent}</TableCell>
                  <TableCell align="center">{row.truckNumber}</TableCell> */}
                  <TableCell align="center">{row.timeStamp}</TableCell>
                  <TableCell align="center">{row.utr}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      disableRipple
                      disabled
                      disableFocusRipple
                      style={{
                        borderColor:
                          row.status == 'processing'
                            ? '#FF7E03'
                            : row.status == 'processed'
                            ? 'green'
                            : row.status == 'reversed'
                            ? 'red'
                            : 'black',
                        textTransform: 'capitalize',
                        color:
                          row.status == 'processing'
                            ? '#FF7E03'
                            : row.status == 'processed'
                            ? 'green'
                            : row.status == 'reversed'
                            ? 'red'
                            : 'black',
                        width: '95px',
                      }}
                    >
                      {row.status}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
