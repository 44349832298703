import axios from 'axios';
import Cookie from 'universal-cookie';

const { REACT_APP_API_ESP_SUPER_ADMIN } = process.env;
const cookies = new Cookie();
export default function apiCaller(endPoint, data) {
  const token = cookies.get('idToken');
  const url = `${REACT_APP_API_ESP_SUPER_ADMIN}${endPoint}`;
  return axios({
    method: 'post',
    url,
    data,
    headers: {
      Authorization: token,
    },
  });
}
