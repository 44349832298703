/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import norecord from '../../../assets/img/norecord.png';
import DocumentDialog from './DocumentDialog';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { backgroundColor: 'black' },
  tableCell: { color: 'white' },
  tableBodyCell: { height: '60px' },
  viewButton: { backgroundColor: '#3366FF' },
});

function createData(
  agentName,
  From,
  To,
  truckNo,
  freight,
  balance,
  approvedAmount,
  date,
  tripId,
  status,
  tripData
) {
  return {
    agentName,
    From,
    To,
    truckNo,
    freight,
    balance,
    approvedAmount,
    date,
    tripId,
    status,
    tripData,
  };
}

export default function DenseTable({ tripDetails, userId, triggerRefresh }) {
  const classes = useStyles();
  const toIndianCurrency = num => {
    if (num) {
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return curr;
    }
    return 0;
  };
  const rows = tripDetails?.map(trip =>
    createData(
      trip.agent_name,
      trip.from_city,
      trip.to_city,
      trip.truck_number,
      trip.freight_value,
      trip.balance,
      trip.approved_balance,
      trip.loading_date,
      trip.trip_id,
      trip.trip_status,
      trip
    )
  );
  if (tripDetails) {
    return (
      <div>
        <TableContainer>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableCell} align="left">
                  Agent Name
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  From City
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  To City
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Truck Number
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  Freight Value
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  Requested Amount
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  Approved Amount
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Date
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Details
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(row => (
                <TableRow
                  key={row.transactionSerial}
                  className={classes.tableBodyCell}
                >
                  <TableCell component="th" scope="row" align="left">
                    {row.agentName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.From}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.To}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.truckNo}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {toIndianCurrency(row.freight)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {toIndianCurrency(row.balance)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {toIndianCurrency(row.approvedAmount)}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.date}
                  </TableCell>

                  <TableCell component="th" scope="row" align="center">
                    <DocumentDialog
                      status={row.status}
                      tripID={row.tripId}
                      userId={userId}
                      balance={row.balance}
                      triggerRefresh={triggerRefresh}
                      tripData={row.tripData}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      disableRipple
                      disabled
                      disableFocusRipple
                      style={{
                        borderColor:
                          row.status == 'need_review'
                            ? '#FF7E03'
                            : row.status == 'approved'
                            ? 'green'
                            : row.status == 'rejected'
                            ? 'red'
                            : 'black',
                        textTransform: 'capitalize',
                        color:
                          row.status == 'need_review'
                            ? '#FF7E03'
                            : row.status == 'approved'
                            ? 'green'
                            : row.status == 'rejected'
                            ? 'red'
                            : 'black',
                        width: '95px',
                      }}
                    >
                      {row.status == 'need_review' ? 'review' : row.status}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  return (
    <Grid container justifyContent="center" align="center">
      <Grid item xs={12}>
        <img src={norecord} alt="emptystate" />
      </Grid>
      <Grid item xs={12}>
        <p style={{ textAlign: 'center', margin: '10px' }}>No Requests Found</p>
      </Grid>
    </Grid>
  );
}
