/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from 'components/CustomButtons/Button.jsx';
import { Snackbar } from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#FCD21F',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(
  date,
  paymentDate,
  amount,
  fees,
  recievedAmount,
  utr,
  truckNumber,
  remarks
) {
  return {
    date,
    paymentDate,
    amount,
    fees,
    recievedAmount,
    utr,
    truckNumber,
    remarks,
  };
}

const defaultButton = props => (
  <Button {...props} color="rose">
    {props.children}
  </Button>
);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables({
  listData,
  offset,
  onNext,
  componentProps,
  onPrev,
  isLoading,
}) {
  const { PageButtonComponent = defaultButton } = componentProps;
  const classes = useStyles();
  const rows = listData?.map(dataList =>
    createData(
      dataList?.loading_date,
      dataList?.payment_date,
      dataList?.amount_transferred,
      dataList?.platform_charges,
      dataList?.received_amount,
      dataList?.utr,
      dataList?.truck_number,
      dataList?.remarks
    )
  );
  const [copiedText, setCopiedText] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const copyToClipboard = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedText(text);
        setOpen(true);
        setCopySuccess('Copied!');
      })
      .catch(error => {
        console.error('Error copying text: ', error);
        setCopySuccess('Copy Failed');
      });
  };
  return (
    <div style={{ marginBottom: '10px' }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Loading Date</StyledTableCell>
              <StyledTableCell>Payment Date</StyledTableCell>
              <StyledTableCell align="left">Amount Transferred</StyledTableCell>
              <StyledTableCell align="left">Platform Charges</StyledTableCell>
              <StyledTableCell align="left">Received Amount</StyledTableCell>
              <StyledTableCell align="left">UTR</StyledTableCell>
              <StyledTableCell align="left">Truck Number</StyledTableCell>
              <StyledTableCell align="left">Remarks</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={8}>
                  <Skeleton variant="rect" width="100%" height={218} />
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              rows.map(row => (
                <StyledTableRow key={row?.utr}>
                  <StyledTableCell component="th" scope="row">
                    {row.date}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.paymentDate}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.amount}</StyledTableCell>
                  <StyledTableCell align="left">{row.fees}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.recievedAmount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {row.utr ? (
                      <>
                        {' '}
                        {row.utr}
                        <FileCopyIcon
                          // fontSize="10px"
                          style={{
                            marginLeft: '10px',
                            fontSize: '14px',
                            cursor: 'pointer',
                          }}
                          onClick={() => copyToClipboard(row.utr)}
                        />
                      </>
                    ) : (
                      '---'
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.truckNumber}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.remarks}</StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>

          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert severity="success">UTR Copied</Alert>
          </Snackbar>
        </Table>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <PageButtonComponent
            className="prev-button"
            disabled={offset === 0}
            onClick={() => onPrev()}
          >
            Previous
          </PageButtonComponent>
          <PageButtonComponent
            className="next-button"
            variant="contained"
            disabled={listData.length < 20}
            onClick={() => onNext()}
          >
            Next
          </PageButtonComponent>
        </div>
      </TableContainer>
    </div>
  );
}
